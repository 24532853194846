enum Actions {
  // action types
  SEARCH_SUPPLIERS = "getSuppliers",
  ADD_SUPPLIER = "addSupplier",
  UPDATE_SUPPLIER = "updateSupplier",
  DELETE_SUPPLIER = "deleteSupplier",
  GET_SUPPLIER = "getSupplier",
  ALL_SUPPLIERS = "getAllSuppliers"
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_SUPPLIERS = "setSuppliers",
  SET_SUPPLIER = "setSupplier",
  SET_ALL_SUPPLIERS = "setAllSuppliers"
}

export { Actions, Mutations };
