enum Actions {
  // action types
  SEARCH_PRODUCT_BOM = "searchProductBOM",
  GET_PRODUCT_BOM = "getProductBOM",
  ADD_PRODUCT_BOM = "addProductBOM",
  UPDATE_PRODUCT_BOM = "updateProductBOM",
  DELETE_PRODUCT_BOM = "deleteProductBOM",
  ADD_BOM_COMMENT = "addBOMComment"
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_PRODUCT_BOM_LIST = "setProductBOMList",
  SET_PRODUCT_BOM = "setProductBOM"
}

export { Actions, Mutations };
