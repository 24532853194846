enum Actions {
  // action types
  SEARCH_SUPPLIER_ORDER_PRODUCTS = "searchSupplierOrderProducts",
  GET_SUPPLIER_ORDER_PRODUCT = "getSupplierOrderProduct",
  ADD_SUPPLIER_ORDER_PRODUCT = "addSupplierOrderProduct",
  UPDATE_SUPPLIER_ORDER_PRODUCT = "updateSupplierOrderProduct",
  DELETE_SUPPLIER_ORDER_PRODUCT = "deleteSupplierOrderProduct"
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_SUPPLIER_ORDER_PRODUCTS = "setSupplierOrderProducts",
  SET_SUPPLIER_ORDER_PRODUCT = "setSupplierOrderProduct"
}

export { Actions, Mutations };
