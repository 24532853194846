import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/ReportsEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface ReportsInterface {

  invoiceNumbers :Array<object>;

}

@Module
export default class ReportsModule extends VuexModule implements ReportsInterface {

  invoiceNumbers = [];

  @Mutation
    [Mutations.SET_INVOICE_NUMBERS](invoiceNumbers) {
    this.invoiceNumbers = invoiceNumbers;
  }

  get allInvoiceNumbers(): Array<object> {
    return this.invoiceNumbers;
  }

  @Action({rawError: true})
  [Actions.GET_COMPONENTS_IN_STOCK_REPORT]() {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("pdf/report/stockLevel/component","",{
        responseType : 'arraybuffer'
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_COMPOSITES_IN_STOCK_REPORT]() {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("pdf/report/stockLevel/composite","",{
        responseType : 'arraybuffer'
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_CUSTOMER_COMPOSITES_IN_STOCK_REPORT]() {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("pdf/report/stockLevel/composite","?type=customer",{
        responseType : 'arraybuffer'
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.LOT_SHIPPING_HISTORY](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("pdf/report/shippingHistory",data,{
        responseType : 'arraybuffer'
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_RETROSPECTIVE_STOCK_LEVELS_REPORT](data){

    const {type} = data;
    delete data['type'];

    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`pdf/report/stockLevel/retrospective/${type}`, data,{
        responseType : 'arraybuffer'
      })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
    });
  }

  @Action({rawError: true})
  [Actions.CREATE_INVOICE](data){

    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`pdf/invoice`, data,{
        responseType : 'arraybuffer'
      })
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            reject(response);
          });
    });
  }

  @Action({rawError: true})
  [Actions.GET_ALL_INVOICES]() {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("pdf/invoiceNumbers")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_INVOICE_NUMBERS, data.invoiceNumbers);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.INVOICE](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("pdf/invoiceNumber",data,{
        responseType : 'arraybuffer'
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

}
