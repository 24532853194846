enum Actions {
  // action types
  SUPPLIER_ORDER_PRODUCTS_TO_DELIVER = "supplierOrderProductsToDeliver",
  SUPPLIER_ORDER_PRODUCTS_DELIVERED = "supplierOrderProductsDelivered",
  GET_SUPPLIER_ORDER_PRODUCT_DELIVERY = "getSupplierOrderProductDelivery",
  ADD_SUPPLIER_ORDER_PRODUCT_DELIVERY = "addSupplierOrderProductDelivery",
  UPDATE_SUPPLIER_ORDER_PRODUCT_DELIVERY = "updateSupplierOrderProductDelivery",
  DELETE_SUPPLIER_ORDER_PRODUCT_DELIVERY = "deleteSupplierOrderProductDelivery"
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_SUPPLIER_ORDER_PRODUCTS_TO_DELIVER = "setSupplierOrderProductsToDeliver",
  SET_SUPPLIER_ORDER_PRODUCTS_DELIVERED = "setSupplierOrderProductsDelivered",
  SET_SUPPLIER_ORDER_PRODUCT_DELIVERY = "setSupplierOrderProductDelivery"
}

export { Actions, Mutations };
