enum Actions {
  // action types
  GET_LOT_BOMS = "getLotBoms",
  ADD_LOT_BOM = "addLotBom",
  UPDATE_LOT_BOM = "updateLotBom",
  DELETE_LOT_BOM = "deleteLotBom"
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_LOT_BOMS = "setLotBoms",
}

export { Actions, Mutations };
