import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/ConsumerEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Consumer {
  firstName: string;
  lastName: string;
  birthday: string;
  address1: string;
  address2: string;
  city: string;
  county: string;
  country: string;
  postcode: string;
  email: string;
  mobile: string;
  telephone: string;
}

export interface Consumers {
  [index: number]: Consumer;
}

export interface ConsumerModuleInfo {
  errors: Array<string>;
  consumers: Consumers;
  consumer: Consumer;
}

@Module
export default class ConsumerModule extends VuexModule implements ConsumerModuleInfo {
  errors = [];
  consumers = [] as Consumers;
  consumer = {} as Consumer;

  /**
   * Get current user object
   * @returns User
   */
  get currentConsumer(): Consumer {
    return this.consumer;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_CONSUMER](consumer) {
    this.consumer = consumer;
  }

  @Mutation
  [Mutations.SET_CONSUMERS](consumers) {
    this.consumers = consumers;
  }

  @Action({rawError: true})
  [Actions.ADD_CONSUMER](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("consumers", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.SEARCH_CONSUMERS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("consumers/all", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.DELETE_CONSUMER](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete("consumers/"+data.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_CONSUMER](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("consumers/"+data.id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CONSUMER, data.consumer);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.UPDATE_CONSUMER](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put("consumers/"+data.id,data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }
}
