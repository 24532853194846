import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { Actions as ReportActions } from "@/store/enums/ReportsEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    meta : {
      requiresAuth : true,
      roles : ["clonallon", "customer"]
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon","customer"]
        },
      },
    ]
  },
  {
    path: "/consumers",
    redirect: "/consumers",
    component: () => import("@/layout/Layout.vue"),
    meta : {
      requiresAuth : true,
      roles : ["clonallon"]
    },
    children: [
      {
        path: "/consumers/list",
        name: "consumers_list",
        component: () => import("@/views/consumers/ConsumerList.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/consumers/add",
        name: "consumer_add",
        component: () => import("@/views/consumers/ConsumerAdd.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/consumers/edit/:id",
        name: "consumer_edit",
        component: () => import("@/views/consumers/ConsumerEdit.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
    ]
  },

  {
    path: "/suppliers",
    redirect: "/suppliers",
    component: () => import("@/layout/Layout.vue"),
    meta : {
      requiresAuth : true,
      roles : ["clonallon"]
    },
    children: [
      {
        path: "/suppliers/list",
        name: "suppliers_list",
        component: () => import("@/views/suppliers/SupplierList.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/suppliers/add",
        name: "supplier_add",
        component: () => import("@/views/suppliers/SupplierAdd.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/suppliers/edit/:id",
        name: "supplier_edit",
        component: () => import("@/views/suppliers/SupplierEdit.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
    ]
  },

  {
    path: "/products",
    redirect: "/products",
    component: () => import("@/layout/Layout.vue"),
    meta : {
      requiresAuth : true,
      roles : ["clonallon"]
    },
    children: [
      {
        path: "/products/list",
        name: "products_list",
        component: () => import("@/views/products/ProductList.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/products/component/add",
        name: "component_add",
        component: () => import("@/views/products/components/ComponentAdd.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/products/component/edit/:id",
        name: "component_edit",
        component: () => import("@/views/products/components/ComponentEdit.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/products/composite/add",
        name: "composite_add",
        component: () => import("@/views/products/composites/CompositeAdd.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/products/composite/edit/:id",
        name: "composite_edit",
        component: () => import("@/views/products/composites/CompositeEdit.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
    ]
  },

  {
    path: "/samples",
    redirect: "/samples",
    component: () => import("@/layout/Layout.vue"),
    meta : {
      requiresAuth : true,
      roles : ["clonallon"]
    },
    children: [
      {
        path: "/samples/list",
        name: "samples_list",
        component: () => import("@/views/samples/SampleList.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/samples/add",
        name: "sample_add",
        component: () => import("@/views/samples/SampleAdd.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/samples/edit/:id",
        name: "sample_edit",
        component: () => import("@/views/samples/SampleEdit.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
    ]
  },

  {
    path: "/lots",
    redirect: "/lots",
    component: () => import("@/layout/Layout.vue"),
    meta : {
      requiresAuth : true,
      roles : ["clonallon"]
    },
    children: [
      {
        path: "/lots/list",
        name: "lots_list",
        component: () => import("@/views/lots/LotsList.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/lots/component/edit/:id",
        name: "lot_component_edit",
        component: () => import("@/views/lots/components/ComponentLotEdit.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/lots/composite/edit/:id",
        name: "lot_composite_edit",
        component: () => import("@/views/lots/composites/CompositeLotEdit.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/lots/assemble-components",
        name: "assemble_components",
        component: () => import("@/views/lots/assemble_components/AssembleComponents.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/lots/invoice-composites",
        name: "invoice_composites",
        component: () => import("@/views/lots/invoice_lots/InvoiceLotsList.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/lots/invoice-composites/add",
        name: "invoice_composites_add",
        component: () => import("@/views/lots/invoice_lots/InvoiceLotsAdd.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/lots/invoice-composites/edit/:id",
        name: "invoice_composites_edit",
        component: () => import("@/views/lots/invoice_lots/InvoiceLotsEdit.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/lots/adjustments",
        name: "adjustments_list",
        component: () => import("@/views/lots/adjustments/AdjustmentsList.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      }
    ]
  },

  {
    path: "/orders",
    redirect: "/orders",
    component: () => import("@/layout/Layout.vue"),
    meta : {
      requiresAuth : true,
      roles : ["clonallon"]
    },
    children: [
      {
        path: "/orders/supplier/list",
        name: "supplier_orders_list",
        component: () => import("@/views/orders/supplier/SupplierOrdersList.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/orders/supplier/add",
        name: "supplier_order_add",
        component: () => import("@/views/orders/supplier/SupplierOrderAdd.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/orders/supplier/edit/:id",
        name: "supplier_order_edit",
        component: () => import("@/views/orders/supplier/SupplierOrderEdit.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/orders/supplier/recorddeliveries/:id",
        name: "supplier_order_record_deliveries",
        component: () => import("@/views/orders/supplier/record_deliveries/SupplierOrderRecordDeliveries.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/orders/consumer/list",
        name: "consumer_orders_list",
        component: () => import("@/views/orders/consumer/ConsumerOrdersList.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/orders/consumer/add",
        name: "consumer_order_add",
        component: () => import("@/views/orders/consumer/ConsumerOrderAdd.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/orders/consumer/edit/:id",
        name: "consumer_order_edit",
        component: () => import("@/views/orders/consumer/ConsumerOrderEdit.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/orders/consumer/recorddispatches/:id",
        name: "consumer_order_record_deliveries",
        component: () => import("@/views/orders/consumer/record_dispatches/ConsumerOrderRecordDispatches.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
    ]
  },
  {
    path: "/reports",
    redirect: "/reports",
    component: () => import("@/layout/Layout.vue"),
    meta : {
      requiresAuth : true,
      roles : ["clonallon", "customer"]
    },
    children: [
      {
        path: "/reports/component-stock-levels",
        name: "component_stock_levels",
        component: () => {

          store.dispatch(Actions.ADD_BODY_CLASSNAME,'page-loading')

          store.dispatch(ReportActions.GET_COMPONENTS_IN_STOCK_REPORT).then((response) => {

            store.dispatch(Actions.REMOVE_BODY_CLASSNAME,'page-loading')

            const blob = new Blob([response], { type: 'application/pdf', filename : 'labRequestForm.pdf' } as object)

            const url = window.URL.createObjectURL(blob)

            window.open(url)

          })
        },
        meta : {
          requiresAuth : true,
          roles : ["clonallon", "customer"]
        },
      },
      {
        path: "/reports/composite-stock-levels",
        name: "composite_stock_levels",
        component: () => {

          store.dispatch(Actions.ADD_BODY_CLASSNAME,'page-loading')

          store.dispatch(ReportActions.GET_COMPOSITES_IN_STOCK_REPORT).then((response) => {

            store.dispatch(Actions.REMOVE_BODY_CLASSNAME,'page-loading')

            const blob = new Blob([response], { type: 'application/pdf', filename : 'compositeStockLevels.pdf' } as object)


            const url = window.URL.createObjectURL(blob)

            window.open(url)

          })
        },
        meta : {
          requiresAuth : true,
          roles : ["clonallon", "customer"]
        },
      },
      {
        path: "/reports/retrospective-component-stock-levels",
        name: "retrospective_component_stock_levels",
        component: () => import("@/views/reports/RetrospectiveComponentStockLevels.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon", "customer"]
        },
      },
      {
        path: "/reports/retrospective-composite-stock-levels",
        name: "retrospective_composite_stock_levels",
        component: () => import("@/views/reports/RetrospectiveCompositeStockLevels.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon", "customer"]
        },
      },
      {
        path: "/reports/lot-shipping-history-by-lot-number",
        name: "lot_shipping_history",
        component: () => import("@/views/reports/LotShippingHistoryByLotNumber.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/reports/lot-shipping-history-by-date",
        name: "lot_shipping_history_by_date",
        component: () => import("@/views/reports/LotShippingHistoryByDate.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/reports/lot-shipping-history-by-order-id",
        name: "lot_shipping_history_by_orderid",
        component: () => import("@/views/reports/LotShippingHistoryByOrderId.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
      {
        path: "/reports/invoice",
        name: "invoice",
        component: () => import("@/views/reports/Invoice.vue"),
        meta : {
          requiresAuth : true,
          roles : ["clonallon"]
        },
      },
    ]
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("@/views/auth/SignIn.vue"),
    meta : {
      requiresAuth : false,
      roles : ["clonallon", "customer"]
    },
  },

  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue")
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404"
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach( (to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // Ensure we checked auth before each page load.
  if(to.meta.requiresAuth){
    store.dispatch(Actions.VERIFY_AUTH).then(() => {

      if(!to.meta.roles.includes(store.getters.currentUser.role.name)){

        router.push('/dashboard')

      }else{

        next();

      }

    }).catch(() => {
      router.push('/sign-in')
    });
  }
  else {
   store.dispatch(Actions.VERIFY_AUTH).then(() => {
      router.push('/dashboard')
    }).catch(() => {
      next();
    });
  }


  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
