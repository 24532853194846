enum Actions {
  // action types
  GET_LOT_PRODUCTION_ACTIONS = "getLotProductionActions",
  ADD_LOT_PRODUCTION_ACTION = "addLotProductionAction",
  UPDATE_LOT_PRODUCTION_ACTION = "updateLotProductionAction",
  DELETE_LOT_PRODUCTION_ACTION = "deleteLotProductionAction",
  UPDATE_PRODUCTION_CONTROL = "updateProductionControl",
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_LOT_PRODUCTION_ACTIONS = "setLotProductionActions",
}

export { Actions, Mutations };
