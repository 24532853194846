enum Actions {
  // action types
  GET_COMPONENTS_IN_STOCK_REPORT = "getComponentsInStockReport",
  GET_COMPOSITES_IN_STOCK_REPORT = "getCompositesInStockReport",
  GET_CUSTOMER_COMPOSITES_IN_STOCK_REPORT = "getCustomerCompositesInStockReport",
  LOT_SHIPPING_HISTORY = "lotShippingHistory",
  GET_RETROSPECTIVE_STOCK_LEVELS_REPORT = "GetRetrospectiveStockLevelsReport",
  CREATE_INVOICE = "createInvoice",
  INVOICE = "invoice",
  GET_ALL_INVOICES = "getAllInvoices"
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_INVOICE_NUMBERS = "setInvoiceNumbers"
}

export { Actions, Mutations };
