enum Actions {
  // action types
  GET_ORDER_STATUSES = "getOrderStatuses",
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_ORDER_STATUSES = "setOrderStatuses",
}

export { Actions, Mutations };
