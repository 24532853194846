import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/ProductionActionsEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface ProductionAction {
  id: number;
  name: string;
}

export interface ProductionActions {
  [index: number]: ProductionAction;
}

export interface ProductionActionsInfo {
  errors: Array<string>;
  productionActions: ProductionActions;
}

@Module
export default class ProductionActionsModule extends VuexModule implements ProductionActionsInfo {
  errors = [];
  productionActions = [] as ProductionActions;


  get myProductionActions(): ProductionActions {
    return this.productionActions;
  }


  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_PRODUCTION_ACTIONS](actions) {
    this.productionActions = actions;
  }

  @Action({rawError: true})
  [Actions.GET_PRODUCTION_ACTIONS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("productionActions")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PRODUCTION_ACTIONS, data.actions);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


}
