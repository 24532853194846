import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/ConsumerOrderRecordDispatchesEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface ConsumerOrderProductToDispatch {
  id: number;
  code: string;
  name: string;
  product_id : number;
  total: number;
  dispatched: number;
  transportUnit: number;
}

export interface ConsumerOrderProductsToDispatch {
  [index: number]: ConsumerOrderProductToDispatch;
}


export interface ConsumerOrderProductDispatched {
  id: number;
  product_id : number;
  reference: string;
  deliveryNote: string;
  deliveryDate: string;
  manufactureDate: string;
  lotNumber: string;
  units: number;
}

export interface ConsumerOrderProductsDispatched {
  [index: number]: ConsumerOrderProductDispatched;
}


export interface ConsumerOrderRecordDispatchesModuleInfo {
  errors: Array<string>;
  productsToDispatch: ConsumerOrderProductsToDispatch;
  productsDispatched: ConsumerOrderProductsDispatched;
  productDispatched: ConsumerOrderProductDispatched;
}

@Module
export default class ConsumerOrderRecordDispatchesModule extends VuexModule implements ConsumerOrderRecordDispatchesModuleInfo {
  errors = [];
  productsToDispatch = [] as ConsumerOrderProductsToDispatch;
  productsDispatched = [] as ConsumerOrderProductsDispatched;
  productDispatched = {} as ConsumerOrderProductDispatched;


  get currentConsumerOrderProductsToDispatch(): ConsumerOrderProductsToDispatch {
    return this.productsToDispatch;
  }

  get currentConsumerOrderProductsDispatched(): ConsumerOrderProductsDispatched {
    return this.productsDispatched;
  }

  get currentConsumerOrderProductDispatched(): ConsumerOrderProductDispatched {
    return this.productDispatched;
  }


  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_CONSUMER_ORDER_PRODUCTS_TO_DISPATCH](products) {
    this.productsToDispatch = products;
  }

  @Mutation
  [Mutations.SET_CONSUMER_ORDER_PRODUCTS_DISPATCHED](products) {
    this.productsDispatched = products;
  }

  @Mutation
  [Mutations.SET_CONSUMER_ORDER_PRODUCT_DISPATCH](product) {
    this.productDispatched = product;
  }


  @Action({rawError: true})
  [Actions.CONSUMER_ORDER_PRODUCTS_TO_DISPATCH](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("consumerOrders/ordered/"+data.id,data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.CONSUMER_ORDER_PRODUCTS_DISPATCHED](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("orders/"+data.id+"/orderProductLot/all",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.ADD_CONSUMER_ORDER_PRODUCT_DISPATCH](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("orderProducts/"+data.order_product_id+"/consumerOrderProductLot",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.UPDATE_CONSUMER_ORDER_PRODUCT_DISPATCH](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put("consumerOrderProductLot/"+data.id,data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.DELETE_CONSUMER_ORDER_PRODUCT_DISPATCH](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete("consumerOrderProductLot/"+data.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.CHECK_KIT_ID](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("consumerOrderProductLot/checkKitId",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.GENERATE_LAB_REQUEST_FORM](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("pdf/labRequestForm",data,{
        responseType : 'arraybuffer'
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GENERATE_REFERENCE_CARD](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("pdf/referenceCard",data,{
        responseType : 'arraybuffer'
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

}
