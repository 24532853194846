import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/DashboardEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class DashboardModule extends VuexModule {

  stats = [];
  activities = [];

  get myStats() {
    return this.stats;
  }

  get myActivities() {
    return this.activities;
  }

  @Mutation
  [Mutations.SET_STATS](stats) {
    this.stats = stats;
  }

  @Mutation
  [Mutations.SET_ACTIVITIES](activities) {
    this.activities = activities;
  }

  @Action({rawError: true})
  [Actions.GET_STATS]() {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("dashboard/stats")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_STATS, data.dashboard);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_ACTIVITIES]() {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("dashboard/activities")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ACTIVITIES, data.activities);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


}
