enum Actions {
  // action types
  SEARCH_CONSUMER_ORDER_PRODUCTS = "searchConsumerOrderProducts",
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_CONSUMER_ORDER_PRODUCTS = "setConsumerOrderProducts",
}

export { Actions, Mutations };
