import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/LotEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Lot {
  id: number;
  code: string;
  type: string;
  lotNumber: string;
  name: string;
}

export interface Lots {
  [index: number]: Lot;
}

export interface LotDeliveryDetails {
  id: number;
  supplier: string;
  deliveryDate: string;
  manufactureDate: string;
  units: number;
}

export interface LotsModuleInfo {
  errors: Array<string>;
  lots: Lots;
  componentLotsOnStock : Lots;
  compositeLotsOnStock : Lots;
  lotDetails: Lot;
  lotDeliveryDetails : LotDeliveryDetails;
  allLots : Lots;
  allCompositeLots : Lots;
}

@Module
export default class LotsModule extends VuexModule implements LotsModuleInfo {
  errors = [];
  lots = [] as Lots;
  componentLotsOnStock = [] as Lots;
  compositeLotsOnStock = [] as Lots;
  lotDetails = {} as Lot;
  lotDeliveryDetails = {} as LotDeliveryDetails;
  allLots = [] as Lots;
  allCompositeLots = [] as Lots;


  get myLots(): Lots {
    return this.lots;
  }

  get mycomponentLotsOnStock(): Lots {
    return this.componentLotsOnStock;
  }

  get mycompositeLotsOnStock(): Lots {
    return this.compositeLotsOnStock;
  }

  get myLotDetails(): Lot {
    return this.lotDetails;
  }

  get myLotDeliveryDetails(): LotDeliveryDetails {
    return this.lotDeliveryDetails;
  }

  get myAllLots(): Lots {
    return this.allLots;
  }

  get myAllCompositeLots(): Lots {
    return this.allCompositeLots;
  }


  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_LOTS](lots) {
    this.lots = lots;
  }

  @Mutation
  [Mutations.SET_ALL_LOTS](lots) {
    this.allLots = lots;
  }

  @Mutation
  [Mutations.SET_LOT](lot) {
    this.lotDetails = lot;
  }

  @Mutation
  [Mutations.SET_COMPONENT_LOTS_ON_STOCK](lots) {
    this.componentLotsOnStock = lots;
  }

  @Mutation
  [Mutations.SET_COMPOSITE_LOTS_ON_STOCK](lots) {
    this.compositeLotsOnStock = lots;
  }

  @Mutation
  [Mutations.SET_LOT_DELIVERY_DETAILS](details) {
    this.lotDeliveryDetails = details;
  }

  @Mutation
  [Mutations.SET_ALL_COMPOSITE_LOTS](lots) {
    this.allCompositeLots = lots;
  }

  @Action({rawError: true})
  [Actions.SEARCH_LOTS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("lots/all",data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LOTS, data.lots);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_ALL_LOTS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("lots/allLots",{})
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_LOTS, data.lots);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_LOT_DETAILS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("lots/details/"+data.id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LOT, data.lot);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.UPDATE_LOT_DETAILS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("lots/details/update/"+data.id,data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_LOT_DELIVERY_DETAILS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("lots/details/delivery/"+data.id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LOT_DELIVERY_DETAILS, data.details);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.UPDATE_LOT_DELIVERY_DETAILS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put("lots/deliveryDetails/update/"+data.id,data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.GET_COMPONENT_LOTS_ON_STOCK](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("lots/getComponentLotsOnStock",{})
        .then(({ data }) => {
          this.context.commit(Mutations.SET_COMPONENT_LOTS_ON_STOCK,data.lots)
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_COMPOSITE_LOTS_ON_STOCK](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("lots/getCompositeLotsOnStock",data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_COMPOSITE_LOTS_ON_STOCK,data.lots)
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.DELETE_LOT](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete("lots/"+data.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_ALL_COMPOSITE_LOTS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("lots/allCompositeLots",{})
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_COMPOSITE_LOTS, data.lots);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }
}
