enum Actions {
  // action types
  GET_LABEL_ACTIONS = "getLabelActions",
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_LABEL_ACTIONS = "setLabelActions",
}

export { Actions, Mutations };
