enum Actions {
    // action types
    SEARCH_SAMPLE_TRANSPORT_COSTS = "searchSampleTransportCosts",
    GET_SAMPLE_TRANSPORT_COST = "getSampleTransportCost",
    ADD_SAMPLE_TRANSPORT_COST = "addSampleTransportCost",
    UPDATE_SAMPLE_TRANSPORT_COST = "updateSampleTransportCost",
    DELETE_SAMPLE_TRANSPORT_COST = "deleteSampleTransportCost"
  }
  
  enum Mutations {
    // mutation types
    SET_ERROR = "setError",
    SET_SAMPLE_TRANSPORT_COSTS = "setSampleTransportCosts",
    SET_SAMPLE_TRANSPORT_COST = "setSampleTransportCost"
  }
  
  export { Actions, Mutations };
  