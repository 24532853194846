import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/ShipmentCarriersEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Carrier {
  id: number;
  name: string;
}

export interface Carriers {
  [index: number]: Carrier;
}

export interface CarriersModuleInfo {
  errors: Array<string>;
  carriers: Carriers;
}

@Module
export default class ShipmentCarriersModule extends VuexModule implements CarriersModuleInfo {
  errors = [];
  carriers = [] as Carriers;


  get myCarriers(): Carriers {
    return this.carriers;
  }


  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_CARRIERS](carriers) {
    this.carriers = carriers;
  }

  @Action({rawError: true})
  [Actions.GET_CARRIERS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("shipmentCarriers")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CARRIERS, data.carriers);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }
}
