enum Actions {
    // action types
    SEARCH_SAMPLE_BOM = "searchSampleBOM",
    GET_SAMPLE_BOM = "getSampleBOM",
    ADD_SAMPLE_BOM = "addSampleBOM",
    UPDATE_SAMPLE_BOM = "updateSampleBOM",
    DELETE_SAMPLE_BOM = "deleteSampleBOM",
    ADD_SAMPLE_BOM_COMMENT = "addSampleBOMComment"
  }
  
  enum Mutations {
    // mutation types
    SET_ERROR = "setError",
    SET_SAMPLE_BOM_LIST = "setSampleBOMList",
    SET_SAMPLE_BOM = "setProductBOM"
  }
  
  export { Actions, Mutations };
  