import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/SamplesEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";


export interface Sample {
  code: string;
  name: string;
  description: string;
  weight: number;
  obsolete: boolean;
  labelsPerPack: number;
  cartonQty: number;
  labelsPerCarton: number;
  additionalLabels: number;
}

export interface Samples {
  [index: number]: Sample;
}


export interface SamplesModuleInfo {
  errors: Array<string>;
  samples: Samples;
  sample: Sample;
}

@Module
export default class SamplesModule extends VuexModule implements SamplesModuleInfo {
  errors = [];
  samples = [] as Samples;
  sample = {} as Sample;


  get currentSample(): Sample {
    return this.sample;
  }

  @Mutation
  [Mutations.SET_SAMPLES](samples) {
    this.samples = samples;
  }

  @Mutation
  [Mutations.SET_SAMPLE](sample) {
    this.sample = sample;
  }

  @Action({rawError: true})
  [Actions.ADD_SAMPLE](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {

      const formData = new FormData() as any;

        for (const [key, value] of Object.entries(data)) {

          formData.append(key, (value == null ? '' : value))

      }

      ApiService.post("samples", formData, {

        'Content-Type': 'multipart/form-data'

      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_SAMPLES](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("samples/all", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_SAMPLE](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("samples/"+data.id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SAMPLE,data.sample)
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.DELETE_SAMPLE](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete("samples/"+data.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.UPDATE_SAMPLE](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {

      const formData = new FormData() as any;

        for (const [key, value] of Object.entries(data)) {

          formData.append(key, (value == null ? '' : value))

      }

      ApiService.post("samples/update/"+data.id,formData,{

        'Content-Type': 'multipart/form-data'

      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.DOWNLOAD_DOCUMENT](data) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader()
      ApiService.post("document", data, {
        responseType: 'arraybuffer'
      })
      .then((res: any) => {
        resolve(res);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  }

  @Action({rawError: true})
  [Actions.GET_SAMPLES_COUNT]() {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("samples/count",{})
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.SEARCH_SAMPLES](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("samples/all", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

}
