enum Actions {
  // action types
  SEARCH_LOTS = "searchLots",
  GET_LOT_DETAILS = "getLotDetails",
  UPDATE_LOT_DETAILS = "updateLotDetails",
  GET_LOT_DELIVERY_DETAILS = "getLotDeliveryDetails",
  UPDATE_LOT_DELIVERY_DETAILS = "updateLotDeliveryDetails",
  DELETE_LOT = "deleteLot",
  GET_COMPONENT_LOTS_ON_STOCK = "getComponentLotsOnStock",
  GET_COMPOSITE_LOTS_ON_STOCK = "getCompositeLotsOnStock",
  GET_ALL_LOTS = "getAllLots",
  GET_ALL_COMPOSITE_LOTS = "getAllCompositeLots"
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_LOTS = "setLots",
  SET_LOT = "setLot",
  SET_LOT_DELIVERY_DETAILS = "setLotDeliveryDetails",
  SET_COMPONENT_LOTS_ON_STOCK = "setComponentLotsOnStock",
  SET_COMPOSITE_LOTS_ON_STOCK = "setCompositeLotsOnStock",
  SET_ALL_LOTS = "setAllLots",
  SET_ALL_COMPOSITE_LOTS = "setAllCompositeLots"
}

export { Actions, Mutations };
