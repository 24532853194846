enum Actions {
  // action types
  GET_STATS = "getStats",
  GET_ACTIVITIES = "getActivities"
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_STATS = "setStats",
  SET_ACTIVITIES = "setActivities"
}

export { Actions, Mutations };
