enum Actions {
  // action types
  GET_CARRIERS = "getCarriers",
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_CARRIERS = "setCarriers",
}

export { Actions, Mutations };
