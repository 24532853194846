enum Actions {
  // action types
  CONSUMER_ORDER_PRODUCTS_TO_DISPATCH = "consumerOrderProductsToDispatch",
  CONSUMER_ORDER_PRODUCTS_DISPATCHED = "consumerOrderProductsDispatched",
  GET_CONSUMER_ORDER_PRODUCT_DISPATCH = "getConsumerOrderProductDispatch",
  ADD_CONSUMER_ORDER_PRODUCT_DISPATCH = "addConsumerOrderProductDispatch",
  UPDATE_CONSUMER_ORDER_PRODUCT_DISPATCH = "updateConsumerOrderProductDispatch",
  DELETE_CONSUMER_ORDER_PRODUCT_DISPATCH = "deleteConsumerOrderProductDispatch",
  CHECK_KIT_ID = "checkKitId",
  GENERATE_LAB_REQUEST_FORM = "generateLabRequestForm",
  GENERATE_REFERENCE_CARD = "generateReferenceCard",
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_CONSUMER_ORDER_PRODUCTS_TO_DISPATCH = "setConsumerOrderProductsToDispatch",
  SET_CONSUMER_ORDER_PRODUCTS_DISPATCHED = "setConsumerOrderProductsDispatched",
  SET_CONSUMER_ORDER_PRODUCT_DISPATCH = "setConsumerOrderProductDispatch"
}

export { Actions, Mutations };
