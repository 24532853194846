enum Actions {
  // action types
  GET_PRODUCT_COMPONENT_TOTALS = "getProductComponentTotals",
  GET_PRODUCT_COMPONENT_ACTIVITIES = "getProductComponentActivities",
  GET_PRODUCT_COMPOSITE_TOTALS = "getProductCompositeTotals",
  GET_PRODUCT_COMPOSITE_ACTIVITIES = "getProductCompositeActivities",
  GET_LOT_COMPONENT_TOTALS = "getLotComponentTotals",
  GET_LOT_COMPONENT_ACTIVITIES = "getLotComponentActivities",
  GET_LOT_COMPOSITE_TOTALS = "getLotCompositeTotals",
  GET_LOT_COMPOSITE_ACTIVITIES = "getLotCompositeActivities"
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_PRODUCT_COMPONENT_TOTALS = "setProductComponentTotals",
  SET_PRODUCT_COMPONENT_ACTIVIES = "setProductComponentActivites",
  SET_PRODUCT_COMPOSITE_TOTALS = "setProductCompositeTotals",
  SET_PRODUCT_COMPOSITE_ACTIVIES = "setProductCompositeActivites",
  SET_LOT_COMPONENT_TOTALS = "setLotComponentTotals",
  SET_LOT_COMPONENT_ACTIVIES = "setLotComponentActivites",
  SET_LOT_COMPOSITE_TOTALS = "setLotCompositeTotals",
  SET_LOT_COMPOSITE_ACTIVIES = "setLotCompositeActivites",
}

export { Actions, Mutations };
