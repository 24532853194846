import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/ConsumerOrderEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Order {
  id: number;
  consumer: {
    id : number;
    firstName : string;
    lastName : string;
  };
  orderFrom_type: string;
  reference: string;
  order_status_id: number;
  currency_id: number;
  user_id: number;
  orderDate: string;
}

export interface Orders {
  [index: number]: Order;
}

export interface ConsumerOrderModuleInfo {
  errors: Array<string>;
  orders: Orders;
  order: Order;
  shippingInfo: any;
}

@Module
export default class ConsumerOrderModule extends VuexModule implements ConsumerOrderModuleInfo {
  errors = [];
  orders = [] as Orders;
  order = {} as Order;
  shippingInfo = {};


  get myConsumerOrders(): Orders {
    return this.orders;
  }

  get currentConsumerOrder(): Order {
    return this.order;
  }

  get orderShippingInfo(): any {
    return this.shippingInfo;
  }



  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_CONSUMER_ORDERS](orders) {
    this.orders = orders;
  }

  @Mutation
  [Mutations.SET_CONSUMER_ORDER](order) {
    this.order = order;
  }

  @Mutation
  [Mutations.SET_SHIPPING_INFO](shippingInfo) {
    this.shippingInfo = shippingInfo;
  }


  @Action({rawError: true})
  [Actions.SEARCH_CONSUMER_ORDERS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("consumerOrders/all", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.GET_CONSUMER_ORDER](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("consumerOrders/"+data.id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CONSUMER_ORDER, data.order);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.UPDATE_CONSUMER_ORDER](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put("consumerOrders/"+data.id,data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_ORDER_SHIPPING_INFO](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("consumerOrders/"+data.id+"/shippingInfo")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SHIPPING_INFO, data.shippingInfo);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


}
