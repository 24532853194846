enum Actions {
  // action types
  GET_PRODUCT_CATEGORIES = "getProductCategories",
  GET_DEVICE_CLASSIFICATION = "getDeviceClassification",
  ADD_COMPONENT = "addComponent",
  ADD_COMPOSITE = "addComposite",
  DELETE_PRODUCT = "deleteProduct",
  SEARCH_PRODUCTS = "searchProducts",
  GET_COMPONENT = "getComponent",
  GET_COMPOSITE = "getComposite",
  UPDATE_PRODUCT = "updateProduct",
  GET_ALL_COMPONENTS = "getAllComponents",
  DOWNLOAD_DOCUMENT = "downloadDocument"
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_PRODUCTS = "setProducts",
  SET_COMPONENT = "setComponent",
  SET_COMPOSITE = "setComposite",
  SET_PRODUCT_CATEGORIES = "setProductCategories",
  SET_DEVICE_CLASSIFICATION = "setDeviceClassification",
  SET_ALL_COMPONENTS = "setAllComponents"
}

export { Actions, Mutations };
