enum Actions {
  // action types
  SEARCH_LOT_ASSEMBLY_COMPONENTS = "searchLotAssemblyBoms",
  SEARCH_LOTS_TO_BE_USED = "searchLotsToBeUsed",
  CREATE_ASSEMBLY = "createAssembly",
  VALIDATE_ASSEMBLY_CREATE = "validateAssemblyCreate",
  GET_LOT_ASSEMBLY = "getLotAssembly"
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_LOT_ASSEMBLY_COMPONENTS = "setLotAssemblyBoms",
  SET_LOTS_TO_BE_USED = "setLotsToBeUsed",
  SET_LOT_ASSEMBLY = "setLotAssembly"
}

export { Actions, Mutations };
