import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/SupplierOrderRecordDeliveriesEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface SupplierOrderProductToDeliver {
  id: number;
  code: string;
  name: string;
  product_id : number;
  total: number;
  received: number;
  transportUnit: number;
}

export interface SupplierOrderProductsToDeliver {
  [index: number]: SupplierOrderProductToDeliver;
}


export interface SupplierOrderProductDelivered {
  id: number;
  product_id : number;
  reference: string;
  deliveryNote: string;
  deliveryDate: string;
  manufactureDate: string;
  lotNumber: string;
  units: number;
}

export interface SupplierOrderProductsDelivered {
  [index: number]: SupplierOrderProductDelivered;
}


export interface SupplierOrderRecordDeliveriesModuleInfo {
  errors: Array<string>;
  productsToDeliver: SupplierOrderProductsToDeliver;
  productsDelivered: SupplierOrderProductsDelivered;
  productDelivered: SupplierOrderProductDelivered;
}

@Module
export default class SupplierOrderRecordDeliveriesModule extends VuexModule implements SupplierOrderRecordDeliveriesModuleInfo {
  errors = [];
  productsToDeliver = [] as SupplierOrderProductsToDeliver;
  productsDelivered = [] as SupplierOrderProductsDelivered;
  productDelivered = {} as SupplierOrderProductDelivered;


  get currentSupplierOrderProductsToDeliver(): SupplierOrderProductsToDeliver {
    return this.productsToDeliver;
  }

  get currentSupplierOrderProductsDelivered(): SupplierOrderProductsDelivered {
    return this.productsDelivered;
  }

  get currentSupplierOrderProductDelivered(): SupplierOrderProductDelivered {
    return this.productDelivered;
  }


  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_SUPPLIER_ORDER_PRODUCTS_TO_DELIVER](products) {
    this.productsToDeliver = products;
  }

  @Mutation
  [Mutations.SET_SUPPLIER_ORDER_PRODUCTS_DELIVERED](products) {
    this.productsDelivered = products;
  }

  @Mutation
  [Mutations.SET_SUPPLIER_ORDER_PRODUCT_DELIVERY](product) {
    this.productDelivered = product;
  }


  @Action({rawError: true})
  [Actions.SUPPLIER_ORDER_PRODUCTS_TO_DELIVER](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("supplierOrders/ordered/"+data.id,data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.SUPPLIER_ORDER_PRODUCTS_DELIVERED](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("orders/"+data.id+"/orderProductLot/all",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.ADD_SUPPLIER_ORDER_PRODUCT_DELIVERY](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("orderProducts/"+data.product_order_id+"/orderProductLot",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.UPDATE_SUPPLIER_ORDER_PRODUCT_DELIVERY](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put("orderProductLot/"+data.id,data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.DELETE_SUPPLIER_ORDER_PRODUCT_DELIVERY](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete("orderProductLot/"+data.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

}
