enum Actions {
  // action types
  ADD_INVOICE_LOT = "addInvoiceLot",
  SEARCH_INVOICE_LOTS = "searchInvoiceLots",
  DELETE_INVOICE_LOT = "deleteInvoiceLot",
  GET_INVOICE_LOT = "getInvoiceLot",
  UPDATE_INVOICE_LOT = "updateInvoiceLot"
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_INVOICE_LOTS = "setInvoiceLots",
  SET_INVOICE_LOT = "setInvoiceLot",
}

export { Actions, Mutations };
