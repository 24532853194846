import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/LotAssembleComponentsEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface LotAssembleComponent {
  id: number;
  code: string;
  name: string;
  required: number;
  inStock: number;
  surplusShortfal : number;
}

export interface LotAssembleComponents {
  [index: number]: LotAssembleComponent;
}

export interface LotToBeUsed {
  productCode: string;
  productName: string;
  lotNumber: string;
  quantity : number;
}

export interface LotsToBeUsed {
  [index: number]: LotToBeUsed;
}

export interface LotAssembly {
  id : number;
  lot_id : number;
  orderDate : string;
  quantity : number;
  sealingMachineNumber : string;
  labelsProduced : number;
  user_id : number;
}

export interface LotAssembleComponentsModuleInfo {
  errors: Array<string>;
  lotAssemblyComponents: LotAssembleComponents;
  lotsToBeUsed: LotsToBeUsed;
  lotAssembly : LotAssembly;
}

@Module
export default class LotAssembleComponentsModule extends VuexModule implements LotAssembleComponentsModuleInfo {
  errors = [];
  lotAssemblyComponents = [] as LotAssembleComponents;
  lotsToBeUsed = [] as LotsToBeUsed;
  lotAssembly = {} as LotAssembly;

  get myLotAssemblyBoms(): LotAssembleComponents {
    return this.lotAssemblyComponents;
  }

  get myLotsToBeUsed(): LotsToBeUsed {
    return this.lotsToBeUsed;
  }

  get myLotAssembly(): LotAssembly {
    return this.lotAssembly;
  }




  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_LOT_ASSEMBLY_COMPONENTS](boms) {
    this.lotAssemblyComponents = boms;
  }

  @Mutation
  [Mutations.SET_LOTS_TO_BE_USED](lots) {
    this.lotsToBeUsed = lots;
  }

  @Mutation
  [Mutations.SET_LOT_ASSEMBLY](assembly) {
    this.lotAssembly = assembly;
  }


  @Action({rawError: true})
  [Actions.SEARCH_LOT_ASSEMBLY_COMPONENTS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("lotAssemblyBoms/all",data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LOT_ASSEMBLY_COMPONENTS, data.boms);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.SEARCH_LOTS_TO_BE_USED](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("lotAssemblyBoms/lotsToBeUsed",data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LOTS_TO_BE_USED, data.lots);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.VALIDATE_ASSEMBLY_CREATE](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("lotAssemblyBoms/validateAssemblyCreate",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.CREATE_ASSEMBLY](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("lotAssemblyBoms/create",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_LOT_ASSEMBLY](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("lotAssemblyBoms/"+data.lot_id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LOT_ASSEMBLY, data.assembly);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

}
