import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/OrderStatusEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface OrderStatus {
  id: number;
  name: string;
}

export interface OrderStatuses {
  [index: number]: OrderStatus;
}

export interface OrderStatusModuleInfo {
  errors: Array<string>;
  orderStatuses: OrderStatuses;
}

@Module
export default class OrderStatusModule extends VuexModule implements OrderStatusModuleInfo {
  errors = [];
  orderStatuses = [] as OrderStatuses;


  get myOrderStatuses(): OrderStatuses {
    return this.orderStatuses;
  }


  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_ORDER_STATUSES](orderStatuses) {
    this.orderStatuses = orderStatuses;
  }

  @Action({rawError: true})
  [Actions.GET_ORDER_STATUSES](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("orders/statuses")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ORDER_STATUSES, data.orderStatuses);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }
}
