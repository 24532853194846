import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/LocationEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Location {
  id: number;
  name: string;
}

export interface Locations {
  [index: number]: Location;
}

export interface LocationModuleInfo {
  errors: Array<string>;
  locations: Locations;
}

@Module
export default class LocationModule extends VuexModule implements LocationModuleInfo {
  errors = [];
  locations = [] as Locations;


  get myLocations(): Locations {
    return this.locations;
  }


  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_LOCATIONS](locations) {
    this.locations = locations;
  }

  @Action({rawError: true})
  [Actions.GET_LOCATIONS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("locations")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LOCATIONS, data.locations);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }
}
