enum Actions {
  // action types
  SEARCH_PRODUCT_SUPPLIERS = "searchProductSuppliers",
  GET_PRODUCT_SUPPLIER = "getProductSupplier",
  ADD_PRODUCT_SUPPLIER = "addProductSupplier",
  UPDATE_PRODUCT_SUPPLIER = "updateProductSupplier",
  DELETE_PRODUCT_SUPPLIER = "deleteProductSupplier"
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_PRODUCT_SUPPLIERS = "setProductSuppliers",
  SET_PRODUCT_SUPPLIER = "setProductSupplier"
}

export { Actions, Mutations };
