enum Actions {
  // action types
  SEARCH_LOT_ADJUSTMENTS = "searchLotAdjustments",
  ADD_LOT_ADJUSTMENT = "addLotAdjustment",
  UPDATE_LOT_ADJUSTMENT = "updateLotAdjustment",
  DELETE_LOT_ADJUSTMENT = "deleteLotAdjustment",
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_LOT_ADJUSTMENTS = "setLotAdjustments",
}

export { Actions, Mutations };
