import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/SupplierOrderEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Order {
  id: number;
  supplier_id: number;
  orderFrom_type: string;
  reference: string;
  order_status_id: number;
  currency_id: number;
  user_id: number;
  orderDate: string;
}

export interface Orders {
  [index: number]: Order;
}

export interface SupplierOrderModuleInfo {
  errors: Array<string>;
  orders: Orders;
  order: Order;
}

@Module
export default class SupplierOrderModule extends VuexModule implements SupplierOrderModuleInfo {
  errors = [];
  orders = [] as Orders;
  order = {} as Order;


  get mySupplierOrders(): Orders {
    return this.orders;
  }
  get currentSupplierOrder(): Order {
    return this.order;
  }


  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_SUPPLIER_ORDERS](orders) {
    this.orders = orders;
  }

  @Mutation
  [Mutations.SET_SUPPLIER_ORDER](order) {
    this.order = order;
  }

  @Action({rawError: true})
  [Actions.ADD_SUPPLIER_ORDER](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("supplierOrders", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.SEARCH_SUPPLIER_ORDERS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("supplierOrders/all", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.DELETE_SUPPLIER_ORDER](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete("supplierOrders/"+data.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_SUPPLIER_ORDER](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("supplierOrders/"+data.id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SUPPLIER_ORDER, data.order);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.UPDATE_SUPPLIER_ORDER](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put("supplierOrders/"+data.id,data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }
}
