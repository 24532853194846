import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/LabelActionsEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface LabelAction {
  id: number;
  name: string;
}

export interface LabelActions {
  [index: number]: LabelAction;
}

export interface LabelActionsInfo {
  errors: Array<string>;
  labelActions: LabelActions;
}

@Module
export default class LabelActionsModule extends VuexModule implements LabelActionsInfo {
  errors = [];
  labelActions = [] as LabelActions;


  get myLabelActions(): LabelActions {
    return this.labelActions;
  }


  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_LABEL_ACTIONS](actions) {
    this.labelActions = actions;
  }

  @Action({rawError: true})
  [Actions.GET_LABEL_ACTIONS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("labelActions")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LABEL_ACTIONS, data.actions);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


}
