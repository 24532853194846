import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/InvoiceLotsEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface InvoiceLot {
  id: number;
  supplier_id: number;
  lot_id: number;
  quantity: number;
}

export interface InvoiceLots {
  [index: number]: InvoiceLot;
}

export interface InvoiceLotsModuleInfo {
  errors: Array<string>;
  invoiceLots: InvoiceLots;
  invoiceLot: InvoiceLot;
}

@Module
export default class InvoiceLotsModule extends VuexModule implements InvoiceLotsModuleInfo {
  errors = [];
  invoiceLots = [] as InvoiceLots;
  invoiceLot = {} as InvoiceLot;

  /**
   * Get current user object
   * @returns User
   */
  get currentInvoiceLot(): InvoiceLot {
    return this.invoiceLot;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_INVOICE_LOT](invoiceLot) {
    this.invoiceLot = invoiceLot;
  }

  @Mutation
  [Mutations.SET_INVOICE_LOTS](invoiceLots) {
    this.invoiceLots = invoiceLots;
  }

  @Action({rawError: true})
  [Actions.ADD_INVOICE_LOT](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("invoiceLots", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.SEARCH_INVOICE_LOTS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("invoiceLots/all", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.DELETE_INVOICE_LOT](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete("invoiceLots/"+data.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_INVOICE_LOT](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("invoiceLots/"+data.id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_INVOICE_LOT, data.invoiceLot);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.UPDATE_INVOICE_LOT](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put("invoiceLots/"+data.id,data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }
}
