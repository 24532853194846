import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/UserEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
}

export interface Users {
  [index: number]: User;
}

export interface UserModuleInfo {
  errors: Array<string>;
  users: Users;
  user: User;
}

@Module
export default class UserModule extends VuexModule implements UserModuleInfo {
  errors = [];
  users = [] as Users;
  user = {} as User;


  get myUsers(): Users {
    return this.users;
  }
  get myUser(): User {
    return this.user;
  }


  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_USERS](users) {
    this.users = users;
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Action({rawError: true})
  [Actions.GET_USERS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("users")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USERS, data.users);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }
}
