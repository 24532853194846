enum Actions {
  // action types
  GET_LOCATIONS = "getLocations",
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_LOCATIONS = "setLocations",
}

export { Actions, Mutations };
