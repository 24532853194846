import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/ProductTransportCostEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface ProductTransportCost {
  id: number;
  product_id : number,
  location_id : number,
  cost : number,
}

export interface ProductTransportCosts {
  [index: number]: ProductTransportCost;
}


export interface ProductTransportCostsModuleInfo {
  errors: Array<string>;
  productTransportCost: ProductTransportCost;
  productTransportCosts: ProductTransportCosts;
}

@Module
export default class ProductTransportCostsModule extends VuexModule implements ProductTransportCostsModuleInfo {
  errors = [];
  productTransportCosts = [] as ProductTransportCosts;
  productTransportCost = {} as ProductTransportCost;


  get currentProductTransportCost(): ProductTransportCost {
    return this.productTransportCost;
  }

  get myProductTransportCosts(): ProductTransportCosts {
    return this.productTransportCosts;
  }


  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_TRANSPORT_COSTS](costs) {
    this.productTransportCosts = costs;
  }

  @Mutation
  [Mutations.SET_TRANSPORT_COST](cost) {
    this.productTransportCost = cost;
  }


  @Action({rawError: true})
  [Actions.SEARCH_TRANSPORT_COSTS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("products/"+data.id+"/transportCost/all",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.ADD_TRANSPORT_COST](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("products/"+data.product_id+"/transportCost",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.UPDATE_TRANSPORT_COST](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put("transportCost/"+data.id,data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.DELETE_TRANSPORT_COST](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete("transportCost/"+data.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

}
