import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/LotLabelsEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface LabelAction {
  id : number;
  lot_id : number;
  label_action_type_id: number;
  statusDate : string;
  position : string;
  quantity : number;
  note : string;
}

export interface LabelActions {
  [index: number]: LabelAction;
}

export interface LabelActionsModuleInfo {
  errors: Array<string>;
  lotLabelActions: LabelActions;
}

@Module
export default class LotLabelsModule extends VuexModule implements LabelActionsModuleInfo {
  errors = [];
  lotLabelActions = [] as LabelActions;


  get currentLotLabelActions(): LabelActions {
    return this.lotLabelActions;
  }


  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_LOT_LABEL_ACTIONS](actions) {
    this.lotLabelActions = actions;
  }


  @Action({rawError: true})
  [Actions.UPDATE_LABEL_CONTROL](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put("lots/"+data.id+"/updateLabelControl",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_LOT_LABEL_ACTIONS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("lots/"+data.id+"/lotLabelAction/all",data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LOT_LABEL_ACTIONS, data.actions);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.ADD_LOT_LABEL_ACTION](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("lots/"+data.lot_id+"/lotLabelAction",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.UPDATE_LOT_LABEL_ACTION](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put("lotLabelAction/"+data.id,data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.DELETE_LOT_LABEL_ACTION](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete("lotLabelAction/"+data.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


}
