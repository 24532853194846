enum Actions {
  // action types
  SEARCH_SUPPLIER_ORDERS = "getSupplierOrders",
  ADD_SUPPLIER_ORDER = "addSupplierOrder",
  UPDATE_SUPPLIER_ORDER = "updateSupplierOrder",
  DELETE_SUPPLIER_ORDER = "deleteSupplierOrder",
  GET_SUPPLIER_ORDER = "getSupplierOrder"
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_SUPPLIER_ORDERS = "setSupplierOrders",
  SET_SUPPLIER_ORDER = "setSupplierOrder"
}

export { Actions, Mutations };
