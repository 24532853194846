enum Actions {
  // action types
  SEARCH_AGREED_PRICES = "searchAgreedPrices",
  GET_AGREED_PRICE = "getAgreedPrice",
  ADD_AGREED_PRICE = "addAgreedPrice",
  UPDATE_AGREED_PRICE = "updateAgreedPrice",
  DELETE_AGREED_PRICE = "deleteAgreedPrice"
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_AGRED_PRICES = "setAgreedPrices",
  SET_AGRED_PRICE = "setAgreedPrice"
}

export { Actions, Mutations };
