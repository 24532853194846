import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/SupplierOrderProductEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface SupplierOrderProduct {
  id: number;
  order_id: number;
  product_id : number;
  units: number;
  costPerUnit: number;
  transportUnit: number;
}

export interface SupplierOrderProducts {
  [index: number]: SupplierOrderProduct;
}


export interface SupplierOrderProductModuleInfo {
  errors: Array<string>;
  product: SupplierOrderProduct;
  products: SupplierOrderProducts;
}

@Module
export default class SupplierOrderProductModule extends VuexModule implements SupplierOrderProductModuleInfo {
  errors = [];
  products = [] as SupplierOrderProducts;
  product = {} as SupplierOrderProduct;


  get currentSupplierOrderProduct(): SupplierOrderProduct {
    return this.product;
  }


  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_SUPPLIER_ORDER_PRODUCTS](products) {
    this.products = products;
  }

  @Mutation
  [Mutations.SET_SUPPLIER_ORDER_PRODUCT](product) {
    this.product = product;
  }


  @Action({rawError: true})
  [Actions.SEARCH_SUPPLIER_ORDER_PRODUCTS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("orders/"+data.id+"/orderProduct/all",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.ADD_SUPPLIER_ORDER_PRODUCT](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("orders/"+data.order_id+"/orderProduct",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.UPDATE_SUPPLIER_ORDER_PRODUCT](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put("orderProduct/"+data.id,data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.DELETE_SUPPLIER_ORDER_PRODUCT](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete("orderProduct/"+data.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

}
