import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/LotProductionEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface ProductionAction {
  id : number;
  lot_id : number;
  production_action_type_id: number;
  startTime : string;
  endTime : string;
  user_id : number;
  quantity : number;
  cartonquantity : number;
}

export interface ProductionActions {
  [index: number]: ProductionAction;
}

export interface ProductionActionsModuleInfo {
  errors: Array<string>;
  lotProductionActions: ProductionActions;
}

@Module
export default class LotProductionModule extends VuexModule implements ProductionActionsModuleInfo {
  errors = [];
  lotProductionActions = [] as ProductionActions;


  get currentLotProductionActions(): ProductionActions {
    return this.lotProductionActions;
  }


  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_LOT_PRODUCTION_ACTIONS](actions) {
    this.lotProductionActions = actions;
  }


  @Action({rawError: true})
  [Actions.UPDATE_PRODUCTION_CONTROL](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put("lotAssemblyBoms/"+data.id+"/updateProductionControl",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_LOT_PRODUCTION_ACTIONS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("lots/"+data.id+"/lotProductionAction/all",data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LOT_PRODUCTION_ACTIONS, data.actions);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.ADD_LOT_PRODUCTION_ACTION](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("lots/"+data.lot_id+"/lotProductionAction",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.UPDATE_LOT_PRODUCTION_ACTION](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put("lotProductionAction/"+data.id,data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.DELETE_LOT_PRODUCTION_ACTION](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete("lotProductionAction/"+data.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


}
