enum Actions {
  // action types
  GET_CURRENCIES = "getCurrencies",
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_CURRENCIES = "setProducts",
}

export { Actions, Mutations };
