enum Actions {
  // action types
  SEARCH_TRANSPORT_COSTS = "searchTransportCosts",
  GET_TRANSPORT_COST = "getTransportCost",
  ADD_TRANSPORT_COST = "addTransportCost",
  UPDATE_TRANSPORT_COST = "updateTransportCost",
  DELETE_TRANSPORT_COST = "deleteTransportCost"
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_TRANSPORT_COSTS = "setTransportCosts",
  SET_TRANSPORT_COST = "setTransportCost"
}

export { Actions, Mutations };
