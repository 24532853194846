enum Actions {
  // action types
  SEARCH_CONSUMER_ORDERS = "getConsumerOrders",
  UPDATE_CONSUMER_ORDER = "updateConsumerOrder",
  DELETE_CONSUMER_ORDER = "deleteConsumerOrder",
  GET_CONSUMER_ORDER = "getConsumerOrder",
  GET_ORDER_SHIPPING_INFO = "getOrderShippingInfo"
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_CONSUMER_ORDERS = "setConsumerOrders",
  SET_CONSUMER_ORDER = "setConsumerOrder",
  SET_SHIPPING_INFO = "setShippingInfo"
}

export { Actions, Mutations };
