import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/ActivitiesEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface ProductComponentTotals {
  onOrderToSupplier: number;
  inStock: number;
}

export interface ProductComponentActivity {
  activityType: string;
  ativityDate: string;
  lotNumber: string;
  quantity: number;
}

export interface ProductComponentActivities {
  [index: number]: ProductComponentActivity;
}



export interface ProductCompositeTotals {
  onOrderToConsumer: number;
  inStock: number;
}

export interface ProductCompositeActivity {
  activityType: string;
  ativityDate: string;
  lotNumber: string;
  quantity: number;
}

export interface ProductCompositeActivities {
  [index: number]: ProductCompositeActivity;
}


export interface LotComponentTotals {
  used: number;
  inStock: number;
}

export interface LotComponentActivity {
  activityType: string;
  ativityDate: string;
  quantity: number;
}

export interface LotComponentActivities {
  [index: number]: LotComponentActivity;
}



export interface LotCompositeTotals {
  dispatched: number;
  inStock: number;
}

export interface LotCompositeActivity {
  activityType: string;
  ativityDate: string;
  quantity: number;
}

export interface LotCompositeActivities {
  [index: number]: LotCompositeActivity;
}



export interface ActivitiesModuleInfo {
  errors: Array<string>;
  productComponentTotals: ProductComponentTotals;
  productComponentActivities: ProductComponentActivities;
  productCompositeTotals: ProductCompositeTotals;
  productCompositeActivities: ProductCompositeActivities;
  lotComponentTotals: LotComponentTotals;
  lotComponentActivities: LotComponentActivities;
  lotCompositeTotals: LotCompositeTotals;
  lotCompositeActivities: LotCompositeActivities
}

@Module
export default class ActivitiesModule extends VuexModule implements ActivitiesModuleInfo {
  errors = [];
  productComponentTotals = {} as ProductComponentTotals;
  productComponentActivities = [] as ProductComponentActivities;
  productCompositeTotals = {} as ProductCompositeTotals;
  productCompositeActivities = [] as ProductCompositeActivities;
  lotComponentTotals = {} as LotComponentTotals;
  lotComponentActivities = [] as LotComponentActivities;
  lotCompositeTotals = {} as LotCompositeTotals;
  lotCompositeActivities = [] as LotCompositeActivities

  get myProductComponentTotals(): ProductComponentTotals {
    return this.productComponentTotals;
  }

  get myProductComponentActivities(): ProductComponentActivities {
    return this.productComponentActivities;
  }

  get myProductCompositeTotals(): ProductCompositeTotals {
    return this.productCompositeTotals;
  }

  get myProductCompositeActivities(): ProductCompositeActivities {
    return this.productCompositeActivities;
  }

  get myLotComponentTotals(): LotComponentTotals {
    return this.lotComponentTotals;
  }

  get myLotComponentActivities(): LotComponentActivities {
    return this.lotComponentActivities;
  }

  get myLotCompositeTotals(): LotCompositeTotals {
    return this.lotCompositeTotals;
  }

  get myLotCompositeActivities(): LotCompositeActivities {
    return this.lotCompositeActivities;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_PRODUCT_COMPONENT_TOTALS](totals) {
    this.productComponentTotals = totals[0];
  }

  @Mutation
  [Mutations.SET_PRODUCT_COMPONENT_ACTIVIES](activities) {
    this.productComponentActivities = activities;
  }

  @Mutation
  [Mutations.SET_PRODUCT_COMPOSITE_TOTALS](totals) {
    this.productCompositeTotals = totals[0];
  }

  @Mutation
  [Mutations.SET_PRODUCT_COMPOSITE_ACTIVIES](activities) {
    this.productCompositeActivities = activities;
  }

  @Mutation
  [Mutations.SET_LOT_COMPONENT_TOTALS](totals) {
    this.lotComponentTotals = totals[0];
  }

  @Mutation
  [Mutations.SET_LOT_COMPONENT_ACTIVIES](activities) {
    this.lotComponentActivities = activities;
  }

  @Mutation
  [Mutations.SET_LOT_COMPOSITE_TOTALS](totals) {
    this.lotCompositeTotals = totals[0];
  }

  @Mutation
  [Mutations.SET_LOT_COMPOSITE_ACTIVIES](activities) {
    this.lotCompositeActivities = activities;
  }

  @Action({rawError: true})
  [Actions.GET_PRODUCT_COMPONENT_TOTALS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("activities/product/"+data.id+"/component/totals")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PRODUCT_COMPONENT_TOTALS, data.totals);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_PRODUCT_COMPONENT_ACTIVITIES](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("activities/product/"+data.id+"/component",data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PRODUCT_COMPONENT_ACTIVIES, data.activities);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.GET_PRODUCT_COMPOSITE_TOTALS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("activities/product/"+data.id+"/composite/totals")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PRODUCT_COMPOSITE_TOTALS, data.totals);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_PRODUCT_COMPOSITE_ACTIVITIES](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("activities/product/"+data.id+"/composite",data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PRODUCT_COMPOSITE_ACTIVIES, data.activities);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }



  @Action({rawError: true})
    [Actions.GET_LOT_COMPONENT_TOTALS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("activities/lot/"+data.id+"/component/totals")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LOT_COMPONENT_TOTALS, data.totals);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_LOT_COMPONENT_ACTIVITIES](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("activities/lot/"+data.id+"/component",data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LOT_COMPONENT_ACTIVIES, data.activities);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
    [Actions.GET_LOT_COMPOSITE_TOTALS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("activities/lot/"+data.id+"/composite/totals")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LOT_COMPOSITE_TOTALS, data.totals);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_LOT_COMPOSITE_ACTIVITIES](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("activities/lot/"+data.id+"/composite",data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LOT_COMPOSITE_ACTIVIES, data.activities);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }



}
