enum Actions {
  // action types
  GET_LOT_LABEL_ACTIONS = "getLotLabelActions",
  ADD_LOT_LABEL_ACTION = "addLotLabelAction",
  UPDATE_LOT_LABEL_ACTION = "updateLotLabelAction",
  DELETE_LOT_LABEL_ACTION = "deleteLotLabelAction",
  UPDATE_LABEL_CONTROL = "updateLabelControl",
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_LOT_LABEL_ACTIONS = "setLotProductionActions",
}

export { Actions, Mutations };
