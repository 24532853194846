enum Actions {
    // action types
    GET_SAMPLES = "getSamples",
    ADD_SAMPLE = "addSample",
    DOWNLOAD_DOCUMENT = "downloadDocument",
    GET_SAMPLE = "getSample",
    DELETE_SAMPLE = "deleteSample",
    UPDATE_SAMPLE = "updateSample",
    GET_SAMPLES_COUNT = "getSamplesCount",
    SEARCH_SAMPLES = "searchSamples"
  }
  
  enum Mutations {
    // mutation types
    SET_ERROR = "setError",
    SET_SAMPLES = "setSamples",
    SET_SAMPLE = "setSample"
  }
  
  export { Actions, Mutations };
  