import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/AgreedPricesEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface AgreedPrice {
  id: number;
  product_id : number,
  supplier_id : number,
  cost : number,
  minOrderQty : number,
  exWorks : boolean,
  currency_id : number,
  contactReference : string,
  startDate : string,
  endDate : string
}

export interface AgreedPrices {
  [index: number]: AgreedPrice;
}


export interface AgreedPricesModuleInfo {
  errors: Array<string>;
  agreedPrice: AgreedPrice;
  agreedPrices: AgreedPrices;
}

@Module
export default class AgreedPricesModule extends VuexModule implements AgreedPricesModuleInfo {
  errors = [];
  agreedPrices = [] as AgreedPrices;
  agreedPrice = {} as AgreedPrice;


  get currentAgreedPrice(): AgreedPrice {
    return this.agreedPrice;
  }


  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AGRED_PRICES](prices) {
    this.agreedPrices = prices;
  }

  @Mutation
  [Mutations.SET_AGRED_PRICE](price) {
    this.agreedPrice = price;
  }


  @Action({rawError: true})
  [Actions.SEARCH_AGREED_PRICES](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("products/"+data.id+"/agreedPrices/all",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.ADD_AGREED_PRICE](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("products/"+data.product_id+"/agreedPrices",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.UPDATE_AGREED_PRICE](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put("agreedPrices/"+data.id,data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.DELETE_AGREED_PRICE](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete("agreedPrices/"+data.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

}
