enum Actions {
  // action types
  ADD_CONSUMER = "addConsumer",
  SEARCH_CONSUMERS = "searchConsumers",
  DELETE_CONSUMER = "deleteConsumer",
  GET_CONSUMER = "getConsumer",
  UPDATE_CONSUMER = "updateConsumer"
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_CONSUMERS = "setConsumers",
  SET_CONSUMER = "setConsumer",
}

export { Actions, Mutations };
