import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/SampleBOMEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface SampleBOM {
  id: number;
  sample_id : number;
  quantity: number;
  code: string;
  name: string;
  cost: number;
}

export interface SampleBOMList {
  [index: number]: SampleBOM;
}


export interface SampleBOMModuleInfo {
  errors: Array<string>;
  sampleBOM: SampleBOM;
  sampleBOMList: SampleBOMList;
}

@Module
export default class SampleBOMModule extends VuexModule implements SampleBOMModuleInfo {
  errors = [];
  sampleBOMList = [] as SampleBOMList;
  sampleBOM = {} as SampleBOM;


  get currentSampleBOM(): SampleBOM {
    return this.sampleBOM;
  }


  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_SAMPLE_BOM_LIST](bomlist) {
    this.sampleBOMList = bomlist;
  }

  @Mutation
  [Mutations.SET_SAMPLE_BOM](bom) {
    this.sampleBOM = bom;
  }


  @Action({rawError: true})
  [Actions.SEARCH_SAMPLE_BOM](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("samples/"+data.id+"/sampleBom/all",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.ADD_SAMPLE_BOM](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("samples/"+data.sample_id+"/sampleBom",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.UPDATE_SAMPLE_BOM](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put("sampleBom/"+data.id,data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.DELETE_SAMPLE_BOM](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete("sampleBom/"+data.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.ADD_SAMPLE_BOM_COMMENT](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put("samples/"+data.id+"/bomComment/",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

}
