import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/CurrencyEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Currency {
  id: number;
  name: string;
}

export interface Currencies {
  [index: number]: Currency;
}

export interface CurrencyModuleInfo {
  errors: Array<string>;
  currencies: Currencies;
}

@Module
export default class CurrencyModule extends VuexModule implements CurrencyModuleInfo {
  errors = [];
  currencies = [] as Currencies;


  get myCurrencies(): Currencies {
    return this.currencies;
  }


  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_CURRENCIES](currencies) {
    this.currencies = currencies;
  }

  @Action({rawError: true})
  [Actions.GET_CURRENCIES](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("currencies")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENCIES, data.currencies);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }
}
