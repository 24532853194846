import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import ApiService from "@/core/services/ApiService.ts";
import "@/core/plugins/keenthemes.ts";
import "popper.js";
import { initApexCharts } from "@/core/plugins/apexcharts";
import ElementPlus from "element-plus";
import VueNextSelect from 'vue-next-select'

import 'vue-next-select/dist/index.min.css'
import "bootstrap";

// import "@/core/plugins/prismjs.ts";

const app = createApp(App);


ApiService.init(app);
initInlineSvg(app);
initApexCharts(app);
app.component('vue-select', VueNextSelect);
app.use(store);
app.use(router);
app.use(ElementPlus);
app.mount("#app");
