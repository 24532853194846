import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/ProductBOMEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface ProductBOM {
  id: number;
  product_id : number;
  quantity: number;
  code: string;
  name: string;
  cost: number;
}

export interface ProductBOMList {
  [index: number]: ProductBOM;
}


export interface ProductBOMModuleInfo {
  errors: Array<string>;
  productBOM: ProductBOM;
  productBOMList: ProductBOMList;
}

@Module
export default class ProductBOMModule extends VuexModule implements ProductBOMModuleInfo {
  errors = [];
  productBOMList = [] as ProductBOMList;
  productBOM = {} as ProductBOM;


  get currentProductBOM(): ProductBOM {
    return this.productBOM;
  }


  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_PRODUCT_BOM_LIST](bomlist) {
    this.productBOMList = bomlist;
  }

  @Mutation
  [Mutations.SET_PRODUCT_BOM](bom) {
    this.productBOM = bom;
  }


  @Action({rawError: true})
  [Actions.SEARCH_PRODUCT_BOM](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("products/"+data.id+"/productBom/all",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.ADD_PRODUCT_BOM](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("products/"+data.composite_id+"/productBom",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.UPDATE_PRODUCT_BOM](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put("productBom/"+data.id,data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.DELETE_PRODUCT_BOM](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete("productBom/"+data.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.ADD_BOM_COMMENT](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put("products/"+data.id+"/bomComment/",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

}
