import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/SupplierEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Supplier {
  name: string;
  address1: string;
  address2: string;
  city: string;
  county: string;
  country: string;
  postcode: string;
  email: string;
  mobile: string;
  telephone: string;
  isCustomer: boolean;
}

export interface Suppliers {
  [index: number]: Supplier;
}

export interface AllSuppliers {
  [index: number]: Supplier;
}

export interface SupplierModuleInfo {
  errors: Array<string>;
  suppliers: Suppliers;
  supplier: Supplier;
  allSuppliers: AllSuppliers;
}

@Module
export default class SupplierModule extends VuexModule implements SupplierModuleInfo {
  errors = [];
  suppliers = [] as Suppliers;
  supplier = {} as Supplier;
  allSuppliers = [] as AllSuppliers;

  /**
   * Get current user object
   * @returns User
   */
  get currentSupplier(): Supplier {
    return this.supplier;
  }

  get myAllSuppliers(): AllSuppliers {
    return this.allSuppliers;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_SUPPLIER](supplier) {
    this.supplier = supplier;
  }

  @Mutation
  [Mutations.SET_SUPPLIERS](suppliers) {
    this.suppliers = suppliers;
  }

  @Mutation
  [Mutations.SET_ALL_SUPPLIERS](suppliers) {
    this.allSuppliers = suppliers;
  }


  @Action({rawError: true})
  [Actions.ADD_SUPPLIER](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("suppliers", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.SEARCH_SUPPLIERS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("suppliers/all", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.DELETE_SUPPLIER](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete("suppliers/"+data.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_SUPPLIER](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("suppliers/"+data.id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SUPPLIER, data.supplier);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.UPDATE_SUPPLIER](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put("suppliers/"+data.id,data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.ALL_SUPPLIERS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("suppliers")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_SUPPLIERS,data.suppliers)
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }
}
