enum Actions {
  // action types
  GET_USERS = "getUsers",
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_USERS = "setUsers",
  SET_USER = "setUser",
}

export { Actions, Mutations };
