import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/ProductSuppliersEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface ProductSupplier {
  id: number;
  supplier_id : number,
}

export interface ProductSuppliers {
  [index: number]: ProductSupplier;
}


export interface ProductSuppliersModuleInfo {
  errors: Array<string>;
  productSupplier: ProductSupplier;
  productSuppliers: ProductSuppliers;
}

@Module
export default class ProductSuppliersModule extends VuexModule implements ProductSuppliersModuleInfo {
  errors = [];
  productSuppliers = [] as ProductSuppliers;
  productSupplier = {} as ProductSupplier;


  get currentProductSupplier(): ProductSupplier {
    return this.productSupplier;
  }


  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_PRODUCT_SUPPLIERS](suppliers) {
    this.productSuppliers = suppliers;
  }

  @Mutation
  [Mutations.SET_PRODUCT_SUPPLIER](supplier) {
    this.productSupplier = supplier;
  }


  @Action({rawError: true})
  [Actions.SEARCH_PRODUCT_SUPPLIERS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("products/"+data.id+"/productSupplier/all",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.ADD_PRODUCT_SUPPLIER](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("products/"+data.product_id+"/productSupplier",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.UPDATE_PRODUCT_SUPPLIER](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put("productSupplier/"+data.id,data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.DELETE_PRODUCT_SUPPLIER](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete("productSupplier/"+data.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

}
