enum Actions {
  // action types
  GET_PRODUCTION_ACTIONS = "getProductionActions",
}

enum Mutations {
  // mutation types
  SET_ERROR = "setError",
  SET_PRODUCTION_ACTIONS = "setProductionActions",
}

export { Actions, Mutations };
