import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/LotAdjustmentsEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface LotAdjustment {
  id: number;
  lot_id : number;
  adjustment : number;
  adjustDate : number;
}

export interface LotAdjustments {
  [index: number]: LotAdjustment;
}

export interface LotAdjustmentsModuleInfo {
  errors: Array<string>;
  lotAdjustments: LotAdjustments;
}

@Module
export default class LotAdjustmentsModule extends VuexModule implements LotAdjustmentsModuleInfo {
  errors = [];
  lotAdjustments = [] as LotAdjustments;


  get myLotAdjustments(): LotAdjustments {
    return this.lotAdjustments;
  }


  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_LOT_ADJUSTMENTS](adjustments) {
    this.lotAdjustments = adjustments;
  }


  @Action({rawError: true})
  [Actions.SEARCH_LOT_ADJUSTMENTS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("adjustments/all",data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LOT_ADJUSTMENTS, data.adjustments);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.ADD_LOT_ADJUSTMENT](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("adjustments",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.UPDATE_LOT_ADJUSTMENT](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put("adjustments/"+data.id,data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.DELETE_LOT_ADJUSTMENT](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete("adjustments/"+data.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

}
