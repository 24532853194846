import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ConsumerModule from "@/store/modules/ConsumerModule";
import ProductModule from "@/store/modules/ProductModule";
import CurrencyModule from "@/store/modules/CurrencyModule";
import SupplierModule from "@/store/modules/SupplierModule";
import AgreedPricesModule from "@/store/modules/AgreedPricesModule";
import ProductSuppliersModule from "@/store/modules/ProductSuppliersModule";
import ProductBOMModule from "@/store/modules/ProductBOMModule";
import LocationModule from "@/store/modules/LocationModule";
import ProductTransportCostsModule from "@/store/modules/ProductTransportCostsModule";
import UserModule from "@/store/modules/UserModule";
import OrderStatusModule from "@/store/modules/OrderStatusModule";
import SupplierOrderModule from "@/store/modules/SupplierOrderModule";
import SupplierOrderProductModule from "@/store/modules/SupplierOrderProductModule";
import SupplierOrderRecordDeliveriesModule from "@/store/modules/SupplierOrderRecordDeliveriesModule";
import LotsModule from "@/store/modules/LotsModule";
import LotAssembleComponentsModule from "@/store/modules/LotAssembleComponentsModule";
import LotBomsModule from "@/store/modules/LotBomsModule";
import LotProductionModule from "@/store/modules/LotProductionModule";
import ProductionActionsModule from "@/store/modules/ProductionActionsModule";
import LabelActionsModule from "@/store/modules/LabelActionsModule";
import LotLabelsModule from "@/store/modules/LotLabelsModule";
import LotAdjustmentsModule from "@/store/modules/LotAdjustmentsModule";
import ConsumerOrderModule from "@/store/modules/ConsumerOrderModule";
import ConsumerOrderProductModule from "@/store/modules/ConsumerOrderProductModule";
import ConsumerOrderRecordDispatchesModule from "@/store/modules/ConsumerOrderRecordDispatchesModule";
import ShipmentCarriersModule from "@/store/modules/ShipmentCarriersModule";
import ActivitiesModule from "@/store/modules/ActivitiesModule";
import ReportsModule from "@/store/modules/ReportsModule";
import DashboardModule from "@/store/modules/DashboardModule";
import InvoiceLotsModule from "@/store/modules/InvoiceLotsModule";
import SamplesModule from "@/store/modules/SamplesModule";
import SamplesBOMModule from "@/store/modules/SamplesBOMModule";
import SampleTransportCostsModule from "@/store/modules/SampleTransportCostsModule";


config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    ConsumerModule,
    ProductModule,
    CurrencyModule,
    SupplierModule,
    AgreedPricesModule,
    ProductSuppliersModule,
    ProductBOMModule,
    LocationModule,
    ProductTransportCostsModule,
    UserModule,
    OrderStatusModule,
    SupplierOrderModule,
    SupplierOrderProductModule,
    SupplierOrderRecordDeliveriesModule,
    LotsModule,
    LotAssembleComponentsModule,
    LotBomsModule,
    LotProductionModule,
    ProductionActionsModule,
    LotLabelsModule,
    LabelActionsModule,
    LotAdjustmentsModule,
    ConsumerOrderModule,
    ConsumerOrderProductModule,
    ConsumerOrderRecordDispatchesModule,
    ShipmentCarriersModule,
    ActivitiesModule,
    ReportsModule,
    DashboardModule,
    InvoiceLotsModule,
    SamplesModule,
    SamplesBOMModule,
    SampleTransportCostsModule
  }
});

export default store;
