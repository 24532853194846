import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/LotBomEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface LotBom {
  code: string;
  quantity: number;
  lotNumber: string;
  name: string;
}

export interface LotBoms {
  [index: number]: LotBom;
}

export interface LotBomsModuleInfo {
  errors: Array<string>;
  lotBoms: LotBoms;
}

@Module
export default class LotBomsModule extends VuexModule implements LotBomsModuleInfo {
  errors = [];
  lotBoms = [] as LotBoms;


  get myLotBoms(): LotBoms {
    return this.lotBoms;
  }


  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_LOT_BOMS](boms) {
    this.lotBoms = boms;
  }


  @Action({rawError: true})
  [Actions.GET_LOT_BOMS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("lotAssemblyBoms/"+data.id,data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_LOT_BOMS, data.lots);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.ADD_LOT_BOM](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("lotAssemblyBoms/"+data.parent_lot_id+"/create",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.UPDATE_LOT_BOM](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put("lotAssemblyBoms/"+data.id,data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.DELETE_LOT_BOM](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete("lotAssemblyBoms/"+data.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

}
