import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/SampleTransportCostsEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface SampleTransportCost {
  id: number;
  sample_id : number,
  location_id : number,
  cost : number,
}

export interface SampleTransportCosts {
  [index: number]: SampleTransportCost;
}


export interface SampleTransportCostsModuleInfo {
  errors: Array<string>;
  sampleTransportCost: SampleTransportCost;
  sampleTransportCosts: SampleTransportCosts;
}

@Module
export default class SampleTransportCostsModule extends VuexModule implements SampleTransportCostsModuleInfo {
  errors = [];
  sampleTransportCosts = [] as SampleTransportCosts;
  sampleTransportCost = {} as SampleTransportCost;


  get currentSampleTransportCost(): SampleTransportCost {
    return this.sampleTransportCost;
  }

  get mySampleTransportCosts(): SampleTransportCosts {
    return this.sampleTransportCosts;
  }


  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_SAMPLE_TRANSPORT_COSTS](costs) {
    this.sampleTransportCosts = costs;
  }

  @Mutation
  [Mutations.SET_SAMPLE_TRANSPORT_COST](cost) {
    this.sampleTransportCost = cost;
  }


  @Action({rawError: true})
  [Actions.SEARCH_SAMPLE_TRANSPORT_COSTS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("samples/"+data.id+"/transportCost/all",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.ADD_SAMPLE_TRANSPORT_COST](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("samples/"+data.sample_id+"/transportCost",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.UPDATE_SAMPLE_TRANSPORT_COST](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.put("sampleTransportCost/"+data.id,data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


  @Action({rawError: true})
  [Actions.DELETE_SAMPLE_TRANSPORT_COST](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete("sampleTransportCost/"+data.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

}
