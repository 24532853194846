import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/ConsumerOrderProductEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface ConsumerOrderProduct {
  id: number;
  order_id: number;
  product_id : number;
  units: number;
  costPerUnit: number;
  transportUnit: number;
}

export interface ConsumerOrderProducts {
  [index: number]: ConsumerOrderProduct;
}


export interface ConsumerOrderProductModuleInfo {
  errors: Array<string>;
  product: ConsumerOrderProduct;
  products: ConsumerOrderProducts;
}

@Module
export default class ConsumerOrderProductModule extends VuexModule implements ConsumerOrderProductModuleInfo {
  errors = [];
  products = [] as ConsumerOrderProducts;
  product = {} as ConsumerOrderProduct;


  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }


  @Action({rawError: true})
  [Actions.SEARCH_CONSUMER_ORDER_PRODUCTS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("consumerOrders/"+data.id+"/orderProduct/all",data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }


}
