import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/ProductEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Component {
  product_type_id: number;
  code: string;
  udi: string;
  name: string;
  description: string;
  product_category_id: number;
  device_classification_id: number;
  obsolete: boolean;
  reOrderLevel: number;
  reOrderQty: number;
  itemsPerCarton: number;
  costPerCarton: number;
  currency_id: number;
  note: string;
}

export interface Composite {
  product_type_id: number;
  code: string;
  udi: string;
  name: string;
  description: string;
  product_category_id: number;
  device_classification_id: number;
  weight: number;
  obsolete: boolean;
  labelsPerPack: number;
  cartonQty: number;
  labelsPerCarton: number;
  additionalLabels: number;
  labelCost: number;
}

export interface Product {
  id: number;
  code: string;
  type: string;
  name: string;
}

export interface Products {
  [index: number]: Product;
}

export interface ProductCategory {
  id: number;
  name: string;
}

export interface ProductCategories {
  [index: number]: ProductCategory;
}

export interface ClassificationCategory {
  id: number;
  name: string;
}

export interface ClassificationCategories {
  [index: number]: ClassificationCategory;
}


export interface ProductModuleInfo {
  errors: Array<string>;
  products: Products;
  component: Component;
  composite: Composite;
  allComponents: Products;
  productCategories: ProductCategories;
  classificationCategories: ClassificationCategories;
}

@Module
export default class ProductModule extends VuexModule implements ProductModuleInfo {
  errors = [];
  products = [] as Products;
  component = {} as Component;
  composite = {} as Composite;
  allComponents = [] as Products;
  productCategories = [] as ProductCategories;
  classificationCategories = [] as ClassificationCategories;


  get currentComponent(): Component {
    return this.component;
  }

  get currentComposite(): Composite {
    return this.composite;
  }

  get myProductCategories(): ProductCategories {
    return this.productCategories;
  }

  get myClassificationCategoies(): ClassificationCategories {
    return this.classificationCategories;
  }

  get myAllComponents(): Products {
    return this.allComponents;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_PRODUCTS](products) {
    this.products = products;
  }

  @Mutation
  [Mutations.SET_COMPONENT](component) {
    this.component = component;
  }

  @Mutation
  [Mutations.SET_COMPOSITE](composite) {
    this.composite = composite;
  }

  @Mutation
  [Mutations.SET_PRODUCT_CATEGORIES](productCategories) {
    this.productCategories = productCategories;
  }

  @Mutation
  [Mutations.SET_DEVICE_CLASSIFICATION](classification) {
    this.classificationCategories = classification;
  }

  @Mutation
  [Mutations.SET_ALL_COMPONENTS](allComponents) {
    this.allComponents = allComponents;
  }

  @Action({rawError: true})
  [Actions.GET_PRODUCT_CATEGORIES]() {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("categories")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PRODUCT_CATEGORIES,data.categories)
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_DEVICE_CLASSIFICATION]() {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("classifications")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DEVICE_CLASSIFICATION,data.classifications)
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.ADD_COMPONENT](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {

      const formData = new FormData() as any;

        for (const [key, value] of Object.entries(data)) {

          formData.append(key, (value == null ? '' : value))

      }

      ApiService.post("products", formData, {

        'Content-Type': 'multipart/form-data'

      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.ADD_COMPOSITE](data) {
    ApiService.setHeader();

    return new Promise((resolve, reject) => {

      const formData = new FormData() as any;

      for (const [key, value] of Object.entries(data)) {

        formData.append(key, (value == null ? '' : value))

      }

      ApiService.post("products", formData, {

        'Content-Type': 'multipart/form-data'

      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.SEARCH_PRODUCTS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("products/all", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.DELETE_PRODUCT](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete("products/"+data.id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_COMPONENT](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("products/"+data.id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_COMPONENT,data.product)
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_COMPOSITE](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("products/"+data.id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_COMPOSITE,data.product)
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.UPDATE_PRODUCT](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {

      const formData = new FormData() as any;

        for (const [key, value] of Object.entries(data)) {

          formData.append(key, (value == null ? '' : value))

      }

      ApiService.post("products/update/"+data.id,formData,{

        'Content-Type': 'multipart/form-data'

      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.GET_ALL_COMPONENTS](data) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("products","?type="+data.type)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_COMPONENTS,data.products)
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  }

  @Action({rawError: true})
  [Actions.DOWNLOAD_DOCUMENT](data) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader()
      ApiService.post("document", data, {
        responseType: 'arraybuffer'
      })
      .then((res: any) => {
        resolve(res);
      })
      .catch(({ response }) => {
        reject(response);
      });
    });
  }

}
